import request from '../request'

//个人评审列表
export const table_judges_index_request = p => {
    return request({
        method:'GET',
        url:'table_judges/index',
        params: p
    })
}

//个人评审详情
export const table_judges_details_request = id => {
    return request({
        method:'GET',
        url:'table_judges/details',
        params: {
            id
        }
    })
}

//个人绩效评审
export const table_judges_appraise_request = d => {
    return request({
        method:'PUT',
        url:'table_judges/appraise',
        data: d
    })
}

//绩效评审核查列表
export const table_judges_sel_request = p => {
    return request({
        method:'GET',
        url:'table_judges/sel',
        params: p
    })
}